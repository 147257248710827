var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew6-white-sheet",
    { staticClass: "mew-component--module-balance-loader pa-6" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-grow-1 align-center",
              attrs: { cols: "12", sm: "6" },
            },
            [
              _c("v-skeleton-loader", { attrs: { type: "avatar" } }),
              _c(
                "div",
                { staticClass: "ml-2 flex-grow-1" },
                [
                  _c("v-skeleton-loader", {
                    staticClass: "mb-2",
                    attrs: {
                      type: "image",
                      "max-width": "150px",
                      height: "15px",
                    },
                  }),
                  _c("v-skeleton-loader", {
                    staticClass: "mb-2",
                    attrs: {
                      type: "image",
                      "max-width": "180px",
                      height: "30px",
                    },
                  }),
                  _c("v-skeleton-loader", {
                    attrs: {
                      type: "image",
                      "max-width": "100px",
                      height: "15px",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-6" },
        [
          _c(
            "v-col",
            {
              staticClass:
                "d-flex align-center justify-center justify-sm-start flex-column flex-sm-row",
              attrs: { cols: "12", sm: "6" },
            },
            [
              _c("v-skeleton-loader", {
                staticClass: "ma-1",
                attrs: { type: "image", width: "130px", height: "20px" },
              }),
              _c("v-skeleton-loader", {
                staticClass: "ma-1",
                attrs: { type: "image", width: "130px", height: "20px" },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center justify-sm-end",
              attrs: { cols: "12", sm: "6" },
            },
            [
              _c("v-skeleton-loader", {
                staticClass: "mr-2",
                attrs: { type: "image", width: "120px", height: "50px" },
              }),
              _c("v-skeleton-loader", {
                attrs: { type: "image", width: "120px", height: "50px" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }